import React from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import { Logojt_img } from "../../assets";


export default function JumbotronComponent ({src}) {
    return(
        <Card className=" text-white" >
      <Card.Img src={src} alt="Card image" />
      <Card.ImgOverlay>
        <div style={{
        backgroundColor: "rgba(0, 0, 0, 0.5", 
        color: "white", 
        display: "flex", 
        flexDirection: "column", 
        justifyContent:"center", 
        alignItems:"center", 
        padding: 20,
        marginTop: 50,
        borderRadius: 12
        
        }} >
        <Card.Title>Wellcome To Our Pages</Card.Title>
        <Image src={Logojt_img} fluid  width={350} />
        <h1>Kesehatan adalah umur bagi hewan peliharaan</h1>
        <Card.Text>
          kami telah siapkan berbagai macam nutrisi pada produk kami sesuai dengan ras, umur, dan masalah kesehatan pada hewan peliharaan anda
        </Card.Text>
        <Card.Text>
          Pelajari produk kami sesuai dengan jenis ras dan umur hewan anda
        </Card.Text>
        <Row>
            <Col>
            <Button style={{backgroundColor: "#00375d", borderColor: "#00375d"}} href="https://happycat.id/" >
                Kucing
            </Button>
            </Col>
            <Col>
            <Button style={{backgroundColor: "#00375d", borderColor: "#00375d"}} href="https://happydog.id/" >
                Anjing
            </Button>
            </Col>
            
        </Row>
        </div>
      </Card.ImgOverlay>
    </Card>
    )
}