import React from "react";
import { NavComponent } from "../../components";
import { Catcards_img, Dogcards_img, Maintron_img } from "../../assets";

export default function RegisterResellerPage() {
  return (
      <div style={{backgroundImage: `url(${Dogcards_img})`,  display:"flex", justifyContent:"center", backgroundSize:"cover"}} >
<iframe width='610px' height='1330px' src='https://crm.zoho.com/crm/WebFormServeServlet?rid=a5fa695caf628706e32aad69db9bf61abe067d3cf997bddb62577c74535907c3gid3dc3e031a67455be100cc084550b5a115e540cba26a2b11dbd6f829106c2a33e'></iframe>
      </div>
  );
}
