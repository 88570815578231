import React from "react";
import { Container, Image, Navbar } from "react-bootstrap";
import { Logohpiwhite_img } from "../../assets";
import { Icon } from "@iconify/react";

export default function FooterComponent() {
  return (
    <Navbar expand="lg" style={{ backgroundColor: "#00375d" }}>
      <Container>
        <Image src={Logohpiwhite_img} width={250} fluid />
        <div>
          <p className="fs-4" style={{ color: "white" }}>
            Alamat kantor
          </p>
          <div style={{ maxWidth: 300 }}>
            <p style={{ color: "white" }}>
              PT. Happy Pet Indonesia World Trade Centre (WTC) 5 - Lt. 10 Jl.
              Jend. Sudirman Kav. 29, Karet Kuningan, Kota Jakarta Selatan 12920
              <br />
              Phone: 021 – 5278567
            </p>
          </div>
        </div>
        <div>
          <p className="fs-4" style={{ color: "white" }}>
            Tentang Kami
          </p>
          <div style={{ maxWidth: 300 }}>
            <p style={{ color: "white" }}>
              Tentang Happy Pet  
            </p>
            <p style={{ color: "white" }}>
              Karir
            </p>
            <p style={{ color: "white" }}>
              Happy Pet Club
            </p>
          </div>
        </div>
        <div>
          <p className="fs-4" style={{ color: "white" }}>
            Contact Kami
          </p>
          <div style={{ maxWidth: 300 }}>
            <p style={{ color: "white" }}>
             Call : 021 – 5278567
            </p>
            <p style={{ color: "white" }}>
             Email : cs-id@happypet.biz
            </p>
            <div style={{display:"flex", justifyContent: "space-around"}} >
                <p style={{color:"white"}} >Follow Us :</p>
                <Icon icon="skill-icons:instagram" />
                <Icon icon="devicon:facebook" />
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}
