import { Route, Router, Routes } from "react-router-dom";
import { HomePage, RegisterConsumerPage, RegisterResellerPage } from "../pages";

export default function RouterConf () {
    return(
        <Routes>
            <Route path="/" Component={HomePage} />
            <Route path="/register-consumer" Component={RegisterConsumerPage} />
            <Route path="/register-reseller" Component={RegisterResellerPage} />
        </Routes>
    )
}