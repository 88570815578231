import React from "react";
import CardImageCaps from "./cardImageCaps";


export default function CardsComponent ({type, src, caps, alt}) {
 if(type === 'imagecaps'){
    return <CardImageCaps src={src} caps={caps} alt={alt} />
 }
 if(type === 'image'){
    return <CardImageCaps src={src} alt={alt} />
 }
}