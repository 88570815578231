import React from "react";
import { NavComponent } from "../../components";
import { Catcards_img, Dogcards_img, Maintron_img } from "../../assets";

export default function RegisterConsumerPage() {
  return (
      <div style={{backgroundImage: `url(${Catcards_img})`,  display:"flex", justifyContent:"center", backgroundSize:"cover"}} >
<iframe width='610px' height='770px' src='https://crm.zoho.com/crm/WebFormServeServlet?rid=a5fa695caf628706e32aad69db9bf61ab44e7677d674346a431dbedf89a9b554gid3dc3e031a67455be100cc084550b5a115e540cba26a2b11dbd6f829106c2a33e'></iframe>
      </div>
  );
}
