import React from "react";
import { Card } from "react-bootstrap";


export default function CardImageCaps ({src, alt, caps}){
    return(

        <Card>
        <Card.Img variant="top" src={src} alt={alt} />
        <Card.Body>
          <Card.Text>
            {caps}
          </Card.Text>
        </Card.Body>
      </Card>
        )
}