import React, { useState } from "react";
import { Nav, Container, NavDropdown, Navbar, Image, Dropdown } from "react-bootstrap";
import { Logohpi_img } from "../../assets";
import { Link } from "react-router-dom";


export default function ManinNavComponent ({clickRConsumer}) {
    
    const [showKucing, setShiowKucing] = useState(false);
    const [showAnjing, setShiowAnjing] = useState(false);
    const [showProduct, setShiowProduct] = useState(false);
    const [showCustomers, setShiowCustomers] = useState(false);
    const [showTentang, setShiowTentang] = useState(false);
    const [showHubungi, setShiowHubungi] = useState(false);
    const showDropdownKucing = (e)=>{
        setShiowKucing(!showKucing);
    }
    const hideDropdownKucing = e => {
        setShiowKucing(false);
    }
    const showDropdownAnjing = (e)=>{
        setShiowAnjing(!showAnjing);
    }
    const hideDropdownAnjing = e => {
        setShiowAnjing(false);
    }
    const showDropdownProduct = (e)=>{
        setShiowProduct(!showProduct);
    }
    const hideDropdownProduct = e => {
        setShiowProduct(false);
    }
    const showDropdownCustomers = (e)=>{
        setShiowCustomers(!showCustomers);
    }
    const hideDropdownCustomers = e => {
        setShiowCustomers(false);
    }
    const showDropdownTentang = (e)=>{
        setShiowTentang(!showTentang);
    }
    const hideDropdownTentang = e => {
        setShiowTentang(false);
    }
    const showDropdownHubungi = (e)=>{
        setShiowHubungi(!showHubungi);
    }
    const hideDropdownHubungi = e => {
        setShiowHubungi(false);
    }
   


    return(
        <Navbar expand="lg" className="bg-body-tertiary" sticky="top" >
      <Container fluid >
        <Link to="/" >
        <Navbar.Brand>
            <Image src={Logohpi_img} height={50} />
        </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse 
        id="responsive-navbar-nav">
            
          <Nav
            className="ms-auto me-5 my-2 my-lg-0"
          >
            <NavDropdown 
            show={showKucing}
            onMouseEnter={showDropdownKucing}
            onMouseLeave={hideDropdownKucing} 
            title="Kucing" 
            id="Dkucing">
              <NavDropdown.Item href="#action4">
                Ras Kucing
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Product Untuk Kucing
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Product Berdasarkan Umur
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Kesehatan Kucing
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Ingin Memelihara Kucing
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Panduan Kesehatan
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown 
            title="Anjing" 
            show={showAnjing} 
            onMouseEnter={showDropdownAnjing} 
            onMouseLeave={hideDropdownAnjing}
            id="navbarScrollingDropdown"
            >
            <NavDropdown.Item href="#action4">
                Ras Anjing
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Product Untuk Anjing
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Product Berdasarkan Umur
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Kesehatan Anjing
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Ingin Memelihara Anjing
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Panduan Kesehatan
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown 
            title="Product" 
            show={showProduct} 
            onMouseEnter={showDropdownProduct} 
            onMouseLeave={hideDropdownProduct}
            id="navbarScrollingDropdown"
            >
              <NavDropdown title="Cari Product Kucing" >
                <Dropdown.Item>
                Berdasarkan Umur
                </Dropdown.Item>
              </NavDropdown>
              <NavDropdown title="Cari Product Anjing" >
                <Dropdown.Item>
                Berdasarkan Umur
                </Dropdown.Item>
              </NavDropdown>
             
            </NavDropdown>
            <NavDropdown 
            title="Customers" 
            show={showCustomers} 
            onMouseEnter={showDropdownCustomers} 
            onMouseLeave={hideDropdownCustomers}
            id="navbarScrollingDropdown"
            >
                <NavDropdown.Item >
                    <Link to="/register-consumer" style={{color:"black", textDecoration: "none" }} >
                Daftar Sebagai Consumer
                    </Link >
                </NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                <Link to="/register-reseller" style={{color:"black", textDecoration: "none" }} >
              Daftar Sebagai Reseller
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Cari Toko
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Cari Klinik Hewan
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Marketplace Online
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#" >
              Nutrisi Khusus
            </Nav.Link>
            <NavDropdown 
            title="Tentang Kami" 
            show={showTentang} 
            onMouseEnter={showDropdownTentang} 
            onMouseLeave={hideDropdownTentang}
            id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="#action3">
                Tentang Happypet
                </NavDropdown.Item>
              <NavDropdown.Item href="https://happypet.zohorecruit.com/recruit/Portal.na?digest=XOaf26tdrMXruhHP8L5.xXGAUnkE7bVo.a85bxdBEmQ-">
                Karir
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Webminar dan Edukasi
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Pakar Kami
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Kualitas dan Keamanan Makanan
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Happy pet Club            
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Berita         
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Panduan Kesehatan Hewan
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
            show={showHubungi}
            onMouseEnter={showDropdownHubungi} 
            onMouseLeave={hideDropdownHubungi}
            title="Hubungi Kami" 
            id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="#action3">
                Contact
                </NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Email
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
                Konsultasi Dengan <br/> Dokter Hewan
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}