import React from "react";
import { CardsComponent, JumbotronComponent, NavComponent } from "../../components";
import { Catcards_img, Dogcards_img, Maintron_img, Petcomunity_img } from "../../assets";
import { Button, Col, Container, Row } from "react-bootstrap";



export default function HomePage () {
    return(
        <div>
        <div>
        <JumbotronComponent src={Maintron_img} />
        </div>
        <div style={{ }} >
            <Container fluid="md" style={{backgroundColor:"white", marginTop: 20 }} >
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}} >
                    <p className="fs-1" style={{textAlign:"center",  color:"#00375d"}} >Tentang Happy Pet Indonesia</p>
                    <div style={{maxWidth:"99vh", color:"#00375d"}} >
                    <p className="fs-4" style={{textAlign:"center"}} >Happy Pet Gulf was founded in January 2022 to bring the super premium 
                    pet food Happy Dog and Happy Cat from Germany to all parts of GCC.</p>
                    <p className="fs-4" style={{textAlign:"center"}} >
                    Setiap product kami dibuat dari bahan natural terbaik yang di suply dari daerah local jerman. Happypet sangat percaya bahwa
                     hewan adalah keluarga bagi kami dan apapun yang kami berikan pada hewan, sama seperti apa yang kami berikan pada diri kami.
                    </p>
                    <p className="fs-4" style={{textAlign:"center"}} >
                    Setiap product yang kami produksi memiliki standar kualitas yang sama seperti makanan manusia
                    . Kami tidak memakain bahan GMO pada product, 
                    baik secara bahan tambahan atau bahan dasar product, karna kami sangan konsentrasi pada kesehatan dan kebahagiaan hewan.
                    </p>
                    <p className="fs-4" style={{textAlign:"center"}} >
                    Kami hadir sebagai rumah bagi makanan hewan yang sehat
                    </p>
                    <p className="fs-3" style={{textAlign:"center"}} >
                    Because all you feed is love.
                    </p>
                    </div>

                </div>
                <div style={{marginTop: 20, padding: 20, backgroundColor: "rgba(0, 0, 0, 0.5", borderRadius: 12 }} >
                <p className="fs-1" style={{color: "white", textAlign:"center"}} > Informasi Nutrisi Dan Kesehatan Hewan </p>
                    <div>
                <p className="fs-5" style={{textAlign:"center", color:"white"}} >Nutrisi adalah kunci kesehatan pada hewan peliharaan, dan setiap nutrisi yang di dapatkan oleh hewan peliharaan sangat bergantung pada apa yang hewan anda makan.</p>
                <p className="fs-5" style={{textAlign:"center", color:"white"}}>Pelajari Trik and Tips seputar nutrisi dan perawatan pada hewan</p>
                    </div>
                <Row>
                    <Col style={{justifyContent: "center", alignItems:"center"}} >
                    <CardsComponent type="imagecaps" src={Catcards_img} alt="catcards" caps="Pelajari lebih lanjut Nutrisi kucing .." />
                    </Col>
                    <Col style={{justifyContent: "center", alignItems:"center",}} >
                    <CardsComponent type="imagecaps" src={Dogcards_img} alt="dogcards" caps="Pelajari lebih lanjut Nutrisi anjing .." />
                    </Col>
                </Row>
                </div>
                <div style={{marginTop: 20}} >
                    <Row>
                        <Col style={{justifyContent:"center", display: "flex", flexDirection:"column"}} >
                        <p className="fs-3" >Happy Pet Club</p>
                        <p className="fs-5" >Bergabung dan dapatkan banyak benefit dimulai dari relasi, 
                        saling bertukar pengalaman, free product, 
                        hingga berbagi pengetahuan seputar hewan anda.</p>
                        <Button style={{backgroundColor: "#00375d", maxWidth: 200}} >Bergabung</Button>
                        </Col>
                        <Col>
                        <CardsComponent type='image' src={Petcomunity_img} alt="petComunity" />
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
        
    </div>
        )
}