import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import RouterConf from './router';
import { Router, Routes } from 'react-router-dom';
import { FooterComponent, NavComponent } from './components';

function App() {
  return (
    <>
    <NavComponent type="main" />
    <RouterConf/>
    <FooterComponent/>
    </>
  )
}

export default App;
